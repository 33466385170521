<div class="wrapper">
  <div class="container" (click)="this.changeImageOnClick()">
    <app-close-session></app-close-session>
    <div class="animation">
      <img
        alt="animated-image"
        id="animated-image"
        src="../../assets/Ball8x.png"
      />
    </div>
  </div>
</div>
