<div class="page-content">
  <div class="text">
    <h1>{{ text }}</h1>
  </div>
  <div class="contentImage" *ngIf="imagePath">
    <img src="{{ imagePath }}" alt="img" class="image" />
  </div>
  <div class="slider" *ngIf="slider">
    <moovd-slider
      [value]="sliderValue"
      (onSliderChange)="onSliderChange($event)"
    ></moovd-slider>
  </div>
</div>
