import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-close-session',
  templateUrl: './close-session.component.html',
  styleUrls: ['./close-session.component.scss'],
})
export class CloseSessionComponent implements OnInit {
  public closeSessionText: string = '';
  constructor(private dataService: DataService) {}

  ngOnInit() {
    /**
     * Get close session text
     */
    this.dataService.getGeneral().subscribe((response) => {
      //console.log(response);
      this.closeSessionText = response.data[0].attributes.closesession;
    });
  }

  /**
   * Resetting the sessionData if user closes session
   */
  public closeSession(): void {
    this.dataService.newSession();
  }
}
