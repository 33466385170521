import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
})
export class FlagComponent implements OnInit {
  @Input() src: string = '';
  @Input() isDisabled = false;

  constructor() {}

  ngOnInit() {}
}
