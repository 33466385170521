export class findNextPage {
  constructor() {}
  /**
   * Search through the data array for a boolean which leads to the ball page.
   * add +1 to the index to dynamiclly get the next page of the app.
   * @returns NextPage index or 0
   */
  protected getNextPageIndex(data: any[], page: string): Number {
    for (let key in data) {
      let element = data[key];
      // checking for the boolean eye_moovement_task_next
      if (element.attributes.eye_moovement_task_next) {
        if (page === 'ball') {
          return Number(key) + 1;
        } else if (page === 'repeater') {
          return Number(key);
        } else {
          console.error('Nextpage does not equal expected.');
        }
      }
    }
    return 0;
  }
}
