import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { findNextPage } from 'src/app/Common/Functions/findNextPage.function';
import { RegisteredSUDs } from 'src/app/Common/interfaces/session_data.interface';
import { DataService } from 'src/app/Common/services/data.service';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent extends findNextPage implements OnInit {
  /**
   * Variables
   */
  // Data array
  data: any[] = [];
  // eye moovement settings
  taskSettings: any[] = [];
  // heading for the text
  public heading: string = '';
  // image url if present
  public image?: string;
  // boolean set to true if present in the json from api
  public slider: boolean = false;
  public sliderValue?: number;
  public isTutorialPage: boolean = false;
  private currentIndex: number = 0;
  public nextDisabled: boolean = false;
  // setting the json.lenght on this variable.
  private dataLenght: number = 0;
  // set this variable to link to another page. Variable set in the button
  public link: string = '';
  // Do not display backbuton on first page
  public transparent = '';
  public disable: boolean = false;
  // 8 rounds is default but this is overwritten by strapi in NgOnInit
  private amountOfRounds = 9;
  // Button text from strapi
  public nextButtonText: string = '';
  public prevButtonText: string = '';

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  private fetchData() {
    /**
     * Get all the data for the steps
     */
    this.dataService.getData().subscribe((response) => {
      if (response.data === null || response.data.length === 0) {
        console.log('Error fetching data');
        return;
      }
      this.data = response.data;
      this.dataLenght = response.data.length - 1;
      this.load();

      this.link = 'step';

      /**
       * Get the eye moovment task settings
       */
      this.dataService.getEyeMoovementSettings().subscribe((response) => {
        this.taskSettings = response.data;
        // - 1 because the rounds count from 0.
        this.amountOfRounds =
          this.taskSettings[0].attributes.amount_of_rounds - 1;
      });

      this.checkNextDisabled();
    });
  }

  ngOnInit() {
    if (!this.dataService.sessionData.UUID) {
      this.router.navigate(['/']);
    }
    this.fetchData();

    /**
     * Checking the queryParams if the user is routed from the repeaterComponent
     */
    this.route.queryParams.subscribe((params) => {
      if (params['index']) {
        this.currentIndex = Number(params['index']);
        this.fetchData();

        this.checkNextDisabled();
      } else if (params['step']) {
        this.currentIndex = Number(params['step']);
        this.fetchData();

        this.checkNextDisabled();
      } else {
        console.log('No params detected');
      }

      this.disableButton();
    });
  }

  /**
   * If the current is 0 or equal to the getNextPageIndex function button will be disabled and color transparent.
   * getNextPageIndex returns the index for the stepComponent after the eye moovement task. And in that situation
   * button needs to be disabled.
   */
  private disableButton(): void {
    if (
      this.currentIndex === 0 ||
      (this.data.length > 0 &&
        this.currentIndex === this.getNextPageIndex(this.data, 'ball'))
    ) {
      this.disable = true;
      this.transparent = 'transparent';
    } else {
      this.disable = false;
      this.transparent = '';
    }
  }

  /**
   * Check if image availible and set in once to the image variable
   */
  private checkAndSetImage(index: number): void {
    const imageData = this.data[index].attributes.image.data;
    if (imageData && imageData.attributes && imageData.attributes.url) {
      this.image = imageData.attributes.url;
    } else {
      this.image = undefined;
    }
  }

  /**
   * Load first data from the DataService and set it.
   */
  public load(): void {
    if (this.data && this.data.length > 0) {
      this.heading = this.data[this.currentIndex].attributes.title;
      this.checkAndSetImage(this.currentIndex);
      this.slider = this.data[this.currentIndex].attributes.slider;
      this.isTutorialPage =
        this.data[this.currentIndex].attributes.is_close_session_tutorial;

      // Set the button text
      this.nextButtonText =
        this.data[this.currentIndex].attributes.next_button_text;
      this.prevButtonText =
        this.data[this.currentIndex].attributes.prev_button_text;
    } else {
      console.log('Error loading data');
      return;
    }
  }

  /**
   * Load the next data from the DataService.
   * Checking if value is true, if so redirect to the eye moovement task
   */
  public next(): void {
    if (this.slider && !this.sliderValue === undefined) {
      console.error('Next step disabled, no slicer or slider value');
      return;
    }

    if (this.slider && this.sliderValue !== undefined) {
      this.registerSUD();
    }

    console.log('-------index------');
    console.log('currentIndex: ' + this.currentIndex);
    console.log('dataLenght: ' + this.dataLenght);

    if (this.currentIndex >= this.dataLenght) {
      // when the currentIndex is equal or greater than the lenght. fire the logic function.
      this.sessionLogic();
    } else if (this.currentIndex <= this.dataLenght) {
      if (this.data[this.currentIndex].attributes.eye_moovement_task_next) {
        this.router.navigate(['ball']);
      } else {
        console.log('-------Next------');
        this.heading = this.data[this.currentIndex + 1].attributes.title;
        this.checkAndSetImage(this.currentIndex + 1);
        this.slider = this.data[this.currentIndex + 1].attributes.slider;
        this.isTutorialPage =
          this.data[this.currentIndex + 1].attributes.is_close_session_tutorial;

        this.sliderValue = undefined;

        // Set the button text
        this.nextButtonText =
          this.data[this.currentIndex + 1].attributes.next_button_text;
        this.prevButtonText =
          this.data[this.currentIndex + 1].attributes.prev_button_text;
      }

      this.heading = this.data[this.currentIndex + 1].attributes.title;
      this.checkAndSetImage(this.currentIndex + 1);
      this.slider = this.data[this.currentIndex + 1].attributes.slider;
      this.isTutorialPage =
        this.data[this.currentIndex + 1].attributes.is_close_session_tutorial;

      this.sliderValue = undefined;

      // Set the button text
      this.nextButtonText =
        this.data[this.currentIndex + 1].attributes.next_button_text;
      this.prevButtonText =
        this.data[this.currentIndex + 1].attributes.prev_button_text;

      this.currentIndex = this.currentIndex + 1;
      // Undisable button if index is > 0
      this.disableButton();
    }

    this.checkNextDisabled();
  }

  /**
   * Previous data from the DataService
   */
  public previous(): void {
    this.currentIndex = this.currentIndex - 1;

    if (this.currentIndex === -1) {
      this.currentIndex = 0;
      this.router.navigate(['startScreen']);
    } else {
      this.heading = this.data[this.currentIndex].attributes.title;
      this.checkAndSetImage(this.currentIndex);
      this.slider = this.data[this.currentIndex].attributes.slider;
      this.isTutorialPage =
        this.data[this.currentIndex].attributes.is_close_session_tutorial;
      this.sliderValue = undefined;
      // Set the button text
      this.nextButtonText =
        this.data[this.currentIndex].attributes.next_button_text;
      this.prevButtonText =
        this.data[this.currentIndex].attributes.prev_button_text;
      // Undisable button if index is > 0
      this.disableButton();

      this.checkNextDisabled();
    }
  }

  /**
   * All the logic for checking the SUD and guiding the
   * User to the right page.
   */
  private sessionLogic(): void {
    // Deconstructing the sessionData into variables
    const { RegisteredSUDs, LastSUD, InitialSUD, Rounds } =
      this.dataService.sessionData;
    const lastIndex = RegisteredSUDs.length - 1;
    const lastSud = RegisteredSUDs[lastIndex].sud;
    const lastSud2 =
      lastIndex >= 1 ? RegisteredSUDs[lastIndex - 1].sud : Number;
    const lastSud3 = lastIndex >= 2 ? RegisteredSUDs[lastIndex - 2].sud : null;

    // Loggin the currently known sesson data
    console.log(this.dataService.sessionData);

    // Checks:
    if (InitialSUD === undefined || LastSUD === undefined) {
      return;
    }

    console.log('in session logic');

    /**
     * Check if sud stays at 10
     */
    if (lastSud === 10 && lastSud2 === 10) {
      console.log('Check 6: true');
      this.router.navigate(['/endScreen'], { queryParams: { type: 'red' } });
      return;
    }

    /**
     * Check if last sud is zero
     */
    if (LastSUD === 0) {
      console.log('Check 5: true');
      this.router.navigate(['/endScreen'], { queryParams: { type: 'green' } });
      return;
    }

    /**
     * Check if there are more than 3 rounds. Then check if the last 3 suds are the same.
     */
    if (Rounds >= 2 && lastSud === lastSud2 && lastSud2 === lastSud3) {
      console.log('Check 3: true');
      this.router.navigate(['/endScreen'], {
        queryParams: { type: 'yellow' },
      });
      return;
    }

    /**
     * Check if the sud has raised between the last round and the one before.
     */
    if (typeof lastSud2 === 'number' && lastSud > lastSud2) {
      console.log('Check 2: true');
      console.log(
        'Show screen with information what to do for the situation the SUD raised'
      );
      this.router.navigate(['/endScreen'], { queryParams: { type: 'red' } });
      return;
    }

    /**
     * Check if the sud has lowered and check if the desired amount of rounds has been reached.
     */
    if (LastSUD < InitialSUD && Rounds >= this.amountOfRounds) {
      console.log('Check 1: true');
      console.log(
        'Show screen with information when somebody walked through 9 rounds of EM'
      );
      if (lastSud === 0) {
        console.log('Green flag');
        this.router.navigate(['/endScreen'], {
          queryParams: { type: 'green' },
        });
        return;
      } else {
        console.log('Yellow flag');
        this.router.navigate(['/endScreen'], {
          queryParams: { type: 'yellow' },
        });
        return;
      }
    }

    /**
     * if there has not been enough rounds route the user to the repeater component
     */
    if (Rounds < this.amountOfRounds) {
      console.log('Check 4');
      console.log('Routing user to the repeater component, not enough rounds');
      this.dataService.sessionData.Rounds++;
      console.log(`Amount of rounds: ${this.dataService.sessionData.Rounds}`);
      this.router.navigate(['/repeater']);
      return;
    }
  }

  private registerSUD(): void {
    console.log('RegisterSUD', this.sliderValue);
    if (this.sliderValue !== undefined) {
      // Check if initial SUD has been set, if not set initial SUD
      if (!this.dataService.sessionData.InitialSUD) {
        this.dataService.sessionData.InitialSUD = this.sliderValue;
      }

      const newSUD: RegisteredSUDs = {
        creationDate: new Date().toISOString(),
        sud: this.sliderValue,
      };
      this.dataService.sessionData.RegisteredSUDs.push(newSUD);

      // Push latest SUD
      this.dataService.sessionData.LastSUD = this.sliderValue;
    }
  }

  public onSUDChange(SUD: number): void {
    console.log('New SUD is', SUD);
    this.sliderValue = SUD;
    this.checkNextDisabled();
  }

  public checkNextDisabled(): void {
    console.log('Check next disabled', this.slider, this.sliderValue);
    if (this.slider && this.sliderValue === undefined) {
      console.log('Next disabled');
      this.nextDisabled = true;
      this.ref.detectChanges();
    } else {
      console.log('Next enabled');
      this.nextDisabled = false;
      this.ref.detectChanges();
    }
  }
}
