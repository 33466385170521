import { ChangeContext } from 'ngx-slider-v2';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'moovd-slider',
  templateUrl: './moovd-slider.component.html',
  styleUrls: ['./moovd-slider.component.scss'],
})
export class MoovdSliderComponent implements OnInit {
  @Input()
  value?: number;
  options: any = {
    floor: 0,
    ceil: 10,
    vertical: true,
  };

  public valueSet: boolean = false;

  // slider text
  public aLot: string = '';
  public notAtAll: string = '';

  @Output() onSliderChange = new EventEmitter<number>();

  constructor(
    private ref: ChangeDetectorRef,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.valueSet = false;
    setTimeout(() => {
      const sliderContainer = document.querySelector(
        '.slider-container'
      ) as HTMLElement;
      const sliderRailSelected = document.querySelector(
        '.ngx-slider-selection'
      ) as HTMLElement;

      if (sliderRailSelected) {
        sliderRailSelected.style.setProperty(
          '--height',
          String(sliderContainer.clientHeight) + 'px'
        );
      }
    }, 50);

    /**
     * Get the slider text language
     */
    this.dataService.getGeneral().subscribe((response) => {
      this.aLot = response.data[0].attributes.slider_1;
      this.notAtAll = response.data[0].attributes.slider_2;
    });
  }

  public onValueChange = (event: number) => {
    this.onSliderChange.emit(event);
    this.ref.detectChanges();
  };

  public detectChanges(event: ChangeContext) {
    this.valueSet = true;
    this.value = event.value;
    this.onSliderChange.emit(event.value);
    this.ref.detectChanges();
  }
}
