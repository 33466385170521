import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { findNextPage } from 'src/app/Common/Functions/findNextPage.function';
import { DataService } from 'src/app/Common/services/data.service';

@Component({
  selector: 'app-repeat-protocol',
  templateUrl: './repeat-protocol.component.html',
  styleUrls: ['./repeat-protocol.component.scss'],
})
export class RepeatProtocolComponent extends findNextPage implements OnInit {
  // data array
  private data: any[] = [];
  // repeater text
  public repeaterText: string = '';
  // button text
  public buttonNextText: string = '';
  // next index
  private index: Number = 0;

  constructor(private dataService: DataService, private router: Router) {
    super();
    console.log('repeater called');
  }

  public navigateUser(): void {
    this.router.navigate(['/step'], {
      queryParams: { index: this.index },
    });
  }

  ngOnInit() {
    /**
     * Get the repeater screen text.
     */
    this.dataService.getRepeaterScreen().subscribe((response) => {
      this.repeaterText = response.data[0].attributes.text;
      this.buttonNextText = response.data[0].attributes.button_next;
    });

    /**
     * Get the data to calculate dynamiclly which page is next in the NextPage().
     */
    this.dataService.getData().subscribe(
      (response) => {
        if (response.data === null) {
          console.log('error');
          return;
        }
        this.data = response.data;
      },
      (error) => {
        console.log('An error occurred:', error);
      },
      () => {
        this.index = this.getNextPageIndex(this.data, 'repeater');
      }
    );
  }
}
