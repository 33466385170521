import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { from, map, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { EndPages } from '../interfaces/end_pages.interface';
import { Pages } from '../interfaces/pages.interface';
import { session_data } from '../interfaces/session_data.interface';
import { v4 as uuidv4 } from 'uuid';
import { eye_m_settings } from '../interfaces/eye_moovement_settings.interface';
import { StartScreenInterface } from '../interfaces/startScreen.interface';
import { RepeaterComponentScreen } from '../interfaces/repeaterScreen.interface';
import { GeneralSettings } from '../interfaces/general.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private apiUrl = environment.strapiUrl + '/api';
  private dataApiUrl = environment.dataApiUrl + '/api';

  public sessionData: session_data = {
    UUID: uuidv4(),
    DeviceID: '7ae145bd-f17f-48b5-94ef-894400f1a9b7',
    RegisteredSUDs: [],
    InitialSUD: undefined,
    LastSUD: undefined,
    Rounds: 1,
  };

  /**
   * Selected language by user by default Turkish (set in the environment)
   */
  public language: string = 'tr';

  /**
   * Function to reset the data when the user navigates from
   * end-screen to the start screen.
   */
  public newSession() {
    this.sessionData.UUID = uuidv4();
    this.sessionData.InitialSUD = undefined;
    this.sessionData.LastSUD = undefined;
    this.sessionData.RegisteredSUDs = [];
    this.sessionData.Rounds = 0;
  }

  /**
   * In the contructor, create a device id which is save in storage and
   * used in every session this user starts.
   * @param http
   */
  constructor(private http: HttpClient) {
    Preferences.get({ key: 'deviceID' }).then((deviceID) => {
      if (!deviceID.value) {
        const newDeviceID = uuidv4();
        Preferences.set({
          key: 'deviceID',
          value: newDeviceID,
        });

        this.sessionData.DeviceID = newDeviceID;
      } else {
        this.sessionData.DeviceID = deviceID.value;
      }
    });
  }

  /**
   * Check language function
   * this is to be called before each query to make sure that were
   * getting the right data.
   */
  async checkPreferences(): Promise<void> {
    const pref = await Preferences.get({ key: 'language' });
    if (pref.value) {
      this.language = pref.value;
    }
  }

  /**
   * Get all the pages from the Strapi api
   * @returns An Observable based on a interface.
   */
  getData(): Observable<Pages> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.http.get<Pages>(
          `${this.apiUrl}/pages?populate=*&sort=rank:asc&locale=${this.language}`
        )
      )
    );
  }

  /**
   * Get all the info for the end pages from the Strapi API
   * @returns An Observable based on a interface.
   */
  getEndPages(): Observable<EndPages> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.http.get<EndPages>(
          `${this.apiUrl}/end-pages?&locale=${this.language}`
        )
      )
    );
  }

  /**
   * Get the setting for the eye moovement task
   */
  getEyeMoovementSettings(): Observable<eye_m_settings> {
    return this.http.get<eye_m_settings>(
      `${this.apiUrl}/eye-moovement-tasks?populate=*`
    );
  }

  /**
   * Get the startScreen text
   */
  getStartScreen(): Observable<StartScreenInterface> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.http.get<StartScreenInterface>(
          `${this.apiUrl}/start-components?locale=${this.language}`
        )
      )
    );
  }

  /**
   * Get the repeaterComponent text
   */
  getRepeaterScreen(): Observable<RepeaterComponentScreen> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.http.get<RepeaterComponentScreen>(
          `${this.apiUrl}/repeater-components?locale=${this.language}`
        )
      )
    );
  }

  /**
   * Get the general settings
   */
  public getGeneral(): Observable<GeneralSettings> {
    return from(this.checkPreferences()).pipe(
      switchMap(() =>
        this.http.get<GeneralSettings>(
          `${this.apiUrl}/generals?locale=${this.language}`
        )
      )
    );
  }

  /**
   * Posting the data to the Dot Net api
   * @param data that is going to be saved, following the session_data interface.
   * @returns ok
   */
  public postRequest(data: session_data) {
    this.http
      .post(`${this.dataApiUrl}/SessionData/addSessionData`, data)
      .subscribe((resp) => {
        console.log(resp);
      });
  }
}
