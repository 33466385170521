import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BallComponent } from './Features/Protocol/ball/ball.component';
import { StartScreenComponent } from './Features/Introduction/start-screen/start-screen.component';
import { StepComponent } from './Features/Introduction/step/step.component';
import { EndScreenComponent } from './Features/Introduction/end-screen/end-screen.component';
import { RepeatProtocolComponent } from './Features/Introduction/repeat-protocol/repeat-protocol.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'startScreen',
    pathMatch: 'full',
  },
  {
    path: 'ball',
    component: BallComponent,
  },
  {
    path: 'step',
    component: StepComponent,
  },
  {
    path: 'endScreen',
    component: EndScreenComponent,
  },
  {
    path: 'repeater',
    component: RepeatProtocolComponent,
  },
  {
    path: 'startScreen',
    component: StartScreenComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
