<div class="wrapper">
  <div class="container">
    <div class="center_text">
      <h1>{{ pageText }}</h1>
      <div class="button_spacing">
        <app-button
          text="{{ closeSessionText }}"
          underline="true"
          (click)="resetSessionData()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
