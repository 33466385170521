import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
})
export class PageContentComponent implements OnInit {
  @Input() text: string = '';
  @Input() imagePath?: string;
  @Input() slider: boolean = false;
  @Input() sliderValue?: number;

  constructor() {}

  ngOnInit() {}

  @Output() onSUDChange = new EventEmitter<number>();

  public onSliderChange = (event: number) => {
    this.onSUDChange.emit(event);
  };
}
