import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoovdCommonModule } from 'src/app/Common/Components/common.module';
import { StartScreenComponent } from './start-screen/start-screen.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { StepComponent } from './step/step.component';
import { EndScreenComponent } from './end-screen/end-screen.component';
import { RepeatProtocolComponent } from './repeat-protocol/repeat-protocol.component';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  declarations: [
    StartScreenComponent,
    StepComponent,
    EndScreenComponent,
    RepeatProtocolComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MoovdCommonModule,
    RouterModule,
    MatButtonToggleModule,
  ],
})
export class IntroductionModule {}
