import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { ButtonComponent } from './button/button.component';
import { CloseSessionComponent } from './close-session/close-session.component';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from '../../app-routing.module';
import { ButtonSmallComponent } from './button-small/button-small.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PageContentComponent } from './page-content/page-content.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { MoovdSliderComponent } from './slider/moovd-slider.component';
import { IonicModule } from '@ionic/angular';
import { FlagComponent } from './flag/flag.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CloseSessionComponent,
    ButtonSmallComponent,
    PageContentComponent,
    MoovdSliderComponent,
    FlagComponent,
  ],
  imports: [
    CommonModule,
    MatSliderModule,
    MatButtonModule,
    AppRoutingModule,
    MatCheckboxModule,
    NgxSliderModule,
    IonicModule.forRoot(),
  ],
  exports: [
    ButtonComponent,
    CloseSessionComponent,
    ButtonSmallComponent,
    PageContentComponent,
    MoovdSliderComponent,
    FlagComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MoovdCommonModule {}
