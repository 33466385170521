import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-small',
  templateUrl: './button-small.component.html',
  styleUrls: ['./button-small.component.scss'],
})
export class ButtonSmallComponent implements OnInit {
  @Input() link = '';
  @Input() text = '';
  @Input() underline = '';
  @Input() disabled: boolean = false;
  @Input() transparent: string = '';

  constructor() {}

  ngOnInit() {}
}
