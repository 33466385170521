import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BallComponent } from './ball/ball.component';
import { MoovdCommonModule } from 'src/app/Common/Components/common.module';

@NgModule({
  declarations: [BallComponent],
  imports: [CommonModule, MoovdCommonModule],
  exports: [BallComponent],
})
export class ProtocolModule {}
