import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EndPages } from 'src/app/Common/interfaces/end_pages.interface';
import { DataService } from 'src/app/Common/services/data.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-end-screen',
  templateUrl: './end-screen.component.html',
  styleUrls: ['./end-screen.component.scss'],
})
export class EndScreenComponent implements OnInit {
  _Activatedroute: any;
  type: any;
  data: any = [];
  page!: Observable<EndPages>;
  pageText: string = '';

  public closeSessionText: string = '';

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    /**
     * Updating session data that the session is completed;
     */
    this.dataService.sessionData.SessionCompleted = true;
  }

  ngOnInit() {
    /**
     * Saving the session data on loading page.
     */
    console.log('Saving Session Data');
    this.dataService.postRequest(this.dataService.sessionData);

    /**
     * Get request for the end page data.
     */
    this.dataService.getEndPages().subscribe((response) => {
      this.data = response.data;

      // loading page data
      this.load(this.type);
    });

    this.route.queryParams.subscribe((params) => {
      this.type = params['type'];
    });

    /**
     * Get close session text
     */
    this.dataService.getGeneral().subscribe((response) => {
      this.closeSessionText = response.data[0].attributes.closesession;
    });
  }

  /**
   * Loading the page data based on the url params
   */
  private load(type: string): void {
    // looking through the json array and checking on type.
    // Setting this to the page variable to display text.
    this.data.forEach((element: any) => {
      if (element.attributes.type === this.type) {
        this.page = element;
        this.pageText = element.attributes.text;
      }
    });
  }

  /**
   * Function for resetting the Session Data after data is saved
   * After resetting, navigate to home screen.
   */
  public resetSessionData(): void {
    // Resetting the session data
    console.log('Resetting the session data');
    this.dataService.newSession();

    console.log('navigation to start screen');
    this.router.navigate(['/']);
  }
}
