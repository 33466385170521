<div class="slider-container" [class.no-value]="!valueSet">
  <p class="slider-max-text">{{ aLot }} <span>😰</span></p>
  <p class="slider-min-text">{{ notAtAll }} <span>🙂</span></p>
  <ngx-slider
    [value]="value ? value : 0"
    [options]="options"
    (userChange)="detectChanges($event)"
    (valueChange)="onValueChange($event)"
    (userChangeEnd)="detectChanges($event)"
  ></ngx-slider>
</div>
