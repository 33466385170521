import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/Common/services/data.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-start-screen',
  templateUrl: './start-screen.component.html',
  styleUrls: ['./start-screen.component.scss'],
})
export class StartScreenComponent implements OnInit {
  // button1: { src: string; isActive: boolean, lang: string } = {
  //   src: '../../../../assets/images/Flag_of_Turkey.svg',
  //   isActive: false,
  //   lang: 'tr'
  // };
  // button2: { src: string; isActive: boolean, lang: string } = {
  //   src: '../../../../assets/images/Flag_of_the_United_Kingdom.svg',
  //   isActive: true,
  //   lang: 'en'
  // };
  // button3: { src: string; isActive: boolean, lang: string } = {
  //   src: '../../../../assets/images/Flag_of_Ukraine.svg',
  //   isActive: false,
  //   lang: 'uk'
  // };

  public welcomeText: string = '';
  public buttonText: string = '';
  public chooseLanguage: string = '';
  public licenseAgree1: string = '';
  public licenseAgree2: string = '';
  public licenseURL: string = '';
  public termsChecked = false;
  public language = this.dataService.language;

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private dataService: DataService
  ) {}

  /**
   * Function is called when the button changes.
   * @param event value of button that changed
   */
  onChange(event: MatButtonToggleChange) {
    this.dataService.language = event.value;

    Preferences.set({
      key: 'language',
      value: event.value,
    });

    this.getStartScreenData();
  }

  public handleStateChanged(lang: string) {
    lang = lang.toLowerCase();
    this.dataService.language =lang;

    Preferences.set({
      key: 'language',
      value: lang,
    });

    this.getStartScreenData();
  }


  ngOnInit() {
    this.getStartScreenData();
  }

  private getStartScreenData() {
    /**
     * Get the start screen text
     */
    this.dataService.getStartScreen().subscribe((response) => {
      this.welcomeText = response.data[0].attributes.text;
      this.buttonText = response.data[0].attributes.button_next;
      this.chooseLanguage = response.data[0].attributes.choose_language;
      this.licenseAgree1 = response.data[0].attributes.license_agreement_1;
      this.licenseAgree2 = response.data[0].attributes.license_agreement_2;
      this.licenseURL = response.data[0].attributes.license_agreement_url;

      /**
       * Set the language switch to that equal of the dataservice
       */
      this.language = this.dataService.language;
    });
  }

  /**
   * Navigating to the step component and setting query param index to 0
   */
  public navigateToStep(): void {
    this.router.navigate(['/step'], { queryParams: { step: 0 } });
  }

  public toggleTermsChecked = (event: any) => {
    this.termsChecked = event.checked;
    this.ref.detectChanges();
  };
}
