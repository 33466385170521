import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { findNextPage } from 'src/app/Common/Functions/findNextPage.function';
import { DataService } from 'src/app/Common/services/data.service';

@Component({
  selector: 'app-ball',
  templateUrl: './ball.component.html',
  styleUrls: ['./ball.component.scss'],
})
export class BallComponent extends findNextPage implements OnInit, OnDestroy {
  /**
   * Variables.
   */
  // data array
  private data: any[] = [];
  // list of image sources for the changeImage() function
  private images: string[] = [];
  // Keep track of the current image displayed.
  private currentImageIndex: number = 0;
  // State for switching
  public currentImageName: string = 'sphere';
  // Interval parameters
  private randomIntervalId: any;
  private minInterval: number = 0;
  private maxInterval: number = 0;
  private taskDuration: number = 0;
  // general interval for moving to the step component.
  private gInterval: any;
  // ball speed set in strap, default is 4s
  private ballSpeed: number = 0;
  // Next page index, default is 0
  private nextPageIndex: number = 0;

  /**
   * Function to change the image.
   */
  public changeImage(): void {
    // Get the next image
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    // Get the image component from the DOM
    const animatedImage = document.getElementById(
      'animated-image'
    ) as HTMLImageElement;
    // Change the source.
    animatedImage.src = this.images[this.currentImageIndex];

    // Check the sourse of the animated image and set the state accordingly
    if (animatedImage.src.includes('Ball')) {
      this.currentImageName = 'Ball';
    } else if (animatedImage.src.includes('Pentagon')) {
      this.currentImageName = 'Pentagon';
    }
  }

  public changeImageOnClick(): void {
    // check state
    if (this.currentImageName == 'Pentagon') {
      // Changing the shape onclick
      this.changeImage();
      // Resetting the interval.
      this.randomInterval();
    }
  }

  /**
   * Generate a random interval which will be return
   * @returns random interval
   */
  private randomInterval(): number {
    // Returning a random interval.
    return Math.floor(
      Math.random() * (this.maxInterval - this.minInterval) + this.minInterval
    );
  }

  /**
   * Start the animation with the generated interval.
   */
  private startImageAnimation(): void {
    this.randomIntervalId = setInterval(() => {
      this.changeImage();
    }, this.randomInterval());
  }

  // CTOR
  constructor(private router: Router, private dataService: DataService) {
    super();
    // set the ball speed in the constructor.
    this.dataService.getEyeMoovementSettings().subscribe((rep) => {
      if (rep.data[0].attributes.active) {
        // setting ball speed
        this.ballSpeed = rep.data[0].attributes.ball_speed;
        let speed = document.querySelector('#animated-image') as HTMLElement;
        speed.style.setProperty('--speed', this.ballSpeed + 's');
      }
    });
  }

  private getEyeMoovementSettings() {
    this.dataService.getEyeMoovementSettings().subscribe((rep) => {
      //console.log(rep);
      if (rep.data[0].attributes.active) {
        // Create variables to push to the image array
        const start = rep.data[0].attributes.start_image.data.attributes.url;
        const change_to =
          rep.data[0].attributes.change_to_image.data.attributes.url;
        this.images.push(start, change_to);

        // set the min & max interval
        this.maxInterval = rep.data[0].attributes.max_interval * 1000;
        this.minInterval = rep.data[0].attributes.min_interval * 1000;
        this.taskDuration = rep.data[0].attributes.task_duration * 1000;
        console.log('Task duration', this.taskDuration);

        // Start animation when the data is set.
        this.startImageAnimation();

        /**
         * added a fail save if nexPage returns 0.
         * it routes home and logs the error.
         */
        if (this.getNextPageIndex(this.data, 'ball') !== 0) {
          this.gInterval = setInterval(() => {
            this.router.navigate(['/step'], {
              queryParams: { index: this.getNextPageIndex(this.data, 'ball') },
            });
          }, this.taskDuration);
        } else {
          // if next page is 0. log error and route user to home.
          this.router.navigate(['/']);
        }
      }
    });
  }

  /**
   * On page init call the following functions.
   */
  ngOnInit() {
    /**
     * Get the data to calculate dynamiclly which page is next in the NextPage().
     */
    this.dataService.getData().subscribe(
      (response) => {
        console.log('Response from backend', response);
        if (response.data === null) {
          console.log('error');
          return;
        }
        this.data = response.data;

        this.getEyeMoovementSettings();
      },
      (error) => {
        console.log('An error occurred:', error);
      },
      () => {
        console.log('Data retrieval completed successfully.');
      }
    );
    /**
     * get the eye moovement setting and set it to it's variables
     */
  }

  ngOnDestroy(): void {
    if (this.randomIntervalId) {
      clearInterval(this.randomIntervalId);
    }
    if (this.gInterval) {
      clearInterval(this.gInterval);
    }
  }
}
