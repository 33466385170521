<a routerLink="{{ link }}">
  <button
    mat-buton
    extended
    class="buttonSmall"
    [ngStyle]="{
      'text-decoration': underline === 'true' ? 'underline' : 'none',
      color: transparent
    }"
    [disabled]="disabled"
  >
    {{ text }}
  </button>
</a>
