<div class="wrapper">
  <div class="container">
    <app-close-session></app-close-session>
    <app-page-content text="{{ repeaterText }}"></app-page-content>
    <div class="bottomScreen">
      <app-button
        text="{{ buttonNextText }}"
        (click)="navigateUser()"
      ></app-button>
    </div>
  </div>
</div>
