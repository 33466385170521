<div class="menu-bar">
  <div class="closeSession">
    <app-button-small
      text="{{ closeSessionText }}"
      link="/startScreen"
      underline="false"
      (click)="closeSession()"
    ></app-button-small>
  </div>
</div>
