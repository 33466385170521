<section class="blue-bg"></section>
<section class="start-session-wrapper">
  <div class="logo">
    <img src="assets/images/moovd_logo.svg" alt="Moovd Logo" />
  </div>
  <div class="content">
    <!-- @TODO REPLACE WITH STRAPI -->
    <p>
      {{ welcomeText }}
    </p>
  </div>
  <div class="actions">
    <app-button
      (click)="navigateToStep()"
      text="{{ buttonText }}"
      [disabled]="!termsChecked"
    ></app-button>
    <div class="terms-wrapper">
      <mat-checkbox
        color="red"
        [checked]="termsChecked"
        (change)="toggleTermsChecked($event)"
        >{{ licenseAgree1 }}
        <a
          href="https://moovd.nl/en/terms-of-use-agreement-for-moovd/"
          target="_blank"
          >{{ licenseAgree2 }}</a
        ></mat-checkbox
      >
    </div>

    <div class="language">
      <p class="lang-text">{{ chooseLanguage }}:</p>
      <!-- <div class="flags-wrapper">
        <app-flag
          class="flag-button"
          [class.disabled]="button2.lang !== language"
          [src]="button2.src"
          (click)="handleStateChanged(button2.lang)"
        >
        </app-flag>
        <app-flag
          class="flag-button"
          [class.disabled]="button1.lang !== language"
          [src]="button1.src"
          (click)="handleStateChanged(button1.lang)"
        >
        </app-flag>
        <app-flag
          class="flag-button"
          [class.disabled]="button3.lang !== language"
          [src]="button3.src"
          (click)="handleStateChanged(button3.lang)"
        >
        </app-flag>
      </div> -->

      <div class="toggle-wrapper">
        <mat-button-toggle-group
          name="fontStyle"
          (change)="onChange($event)"
          value="{{ language }}"
        >
          <mat-button-toggle class="tr" value="tr">TR</mat-button-toggle>
          <mat-button-toggle class="en" value="en">EN</mat-button-toggle>
          <mat-button-toggle class="uk" value="uk">UK</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</section>
