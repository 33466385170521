<div class="wrapper">
  <div class="container" [class.is-tutorial]="isTutorialPage">
    <app-close-session></app-close-session>
    <ng-container *ngIf="isTutorialPage">
      <div class="logo">
        <img
          src="assets/images/instruction_arrow.svg"
          alt="Instruction arrow"
        />
      </div>
    </ng-container>
    <app-page-content
      class="page-content"
      text="{{ heading }}"
      imagePath="{{ image }}"
      [slider]="slider"
      [sliderValue]="sliderValue"
      (onSUDChange)="onSUDChange($event)"
    ></app-page-content>
    <div class="bottomScreen">
      <app-button
        (click)="next()"
        link="{{ link }}"
        text="{{ nextButtonText }}"
        [disabled]="nextDisabled"
      ></app-button>
      <app-button-small
        link="/{{ link }}"
        text="{{ prevButtonText }}"
        underline="false"
        (click)="previous()"
        [disabled]="disable"
        [transparent]="transparent"
      ></app-button-small>
    </div>
  </div>
</div>
